@import '~antd/dist/antd.css';
@import '~ant-design-pro/dist/ant-design-pro.css';

.loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
}
