.full-page {
  height: 100vh;
}

.logo {
  background: url('/images/text-image-non-logo-64.png') no-repeat 50% 50%;
  background-size: contain;
  height: 64px;
  width: 300px;
  /* margin: 0 24px; */
  float: left;
}
.logo-mobile {
  background: url('/images/logo.png') no-repeat 50% 50%;
  background-size: contain;
  height: 64px;
  width: 64px;
  margin: 0 10px;
  float: left;
}

.header-title {
  width: 50%;
  float: left;
  font-weight: bold;
  font-size: 1.2em;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 200px;
  line-height: 200px;
  background: #364d79;
  overflow: hidden;
}
.ant-carousel .slick-slide h3 {
  color: #fff;
}

* {
  box-sizing: border-box;
}
body {
  margin: 0;
}
section.homepage {
  width: 100%;
  display: table;
  margin: 0;
  max-width: none;
  height: 90vh;
  background: url(images/background-image.jpg) no-repeat center center !important;
  background-size: cover;
}
section.homepage:nth-of-type(2n) {
  background-color: #fe4b74;
}
section.homepage .content {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
section.homepage .ant-btn-round.ant-btn-lg {
  font-size: 2em;
  padding: 0.5em 2em;
  height: 3em;
  border-radius: 1.5em;
}
section.homepage h1 {
  font-size: 4em;
  display: block;
  color: white;
  font-weight: 400;
  letter-spacing: -1px;
}
section.homepage p {
  font-size: 1.5em;
  font-weight: 500;
  color: #c3cad9;
}
section.homepage a {
  font-weight: 700;
  color: #373b44;
  position: relative;
}
section.homepage a:hover {
  opacity: 0.8;
}
section.homepage a:active {
  top: 1px;
}

.ant-menu-vertical {
  border: 0;
}
